<template>
    <v-container>
        <h1 class="mb-1">Impostazioni</h1>

        <v-card
        :loading="loading"
        outlined>
            <v-card-text v-if="users">
                <v-data-table
                :headers="headers"
                :items="users">
                    <!-- <template #item.createdAt="{ item }">
                        {{ $utils.dateToHuman(item.createdAt) }}
                    </template>

                    <template #item.lastLog="{ item }">
                        {{ $utils.dateToHuman(item.lastLog) }}
                    </template>

                    <template #item.actions="{ item }">
                        <v-icon @click="test(item._id)">mdi-test-tube</v-icon>
                        <v-icon @click="getOne(item._id)">mdi-pencil</v-icon>
                        <v-icon @click="deleteItem(item._id)">mdi-delete</v-icon>
                    </template> -->
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'SettingsView',
    data () {
        return {
            users: null,
            headers: [
                {
                    text: 'Nome',
                    align: 'start',
                    value: 'name'
                },
                {
                    text: 'ID',
                    value: '_id'
                },
                {
                    text: 'Creazione',
                    value: 'createdAt'
                },
                {
                    text: 'Ultimo accesso',
                    value: 'lastLog'
                },
                {
                    text: 'Azione',
                    sortable: false,
                    align: 'end',
                    value: 'actions'
                }
            ],
            loading: false
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loading = true

            this.$http.get('').then((res) => {
                this.users = res.data
                this.loading = false
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        },
        deleteItem (id) {
            this.loading = true

            this.$http.delete(id).then(() => {
                this.loadData()
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        },
        getOne (id) {
            this.loading = true

            this.$http.get(id).then((res) => {
                console.log(res)
                this.$store.commit('SET_SNACKBAR', {
                    color: 'success',
                    text: res.data,
                    on: true
                })
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        },
        test () {
            this.loading = true

            this.$http.post('test', { text: 'Numero 1' }).then(() => {
                this.loadData()
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        }
    }
}
</script>
