import axios from 'axios'
import utils from '@/assets/libs/utils'

const token = localStorage.getItem('token')
const user = token ? utils.parseJwt(token) : null

console.log('process.env', process.env)

const apis = {
    user
}

// INSTANCE
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + 'v1/',
    headers: {
        Authorization: token
    },
    timeout: 5000
})

// RESOURCES
const methods = {
    // config
    updateConfig: (body) => {
        axios.defaults.headers.common['Authorization'] = body.token
    },

    // methods
    get: (path) => {
        return new Promise((resolve, reject) => {

            return instance.get(path)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
        })
    },

    post: (path, body) => {
        return new Promise((resolve, reject) => {
            return instance.post(path, body).then((res) => {
                if (res.status < 400) resolve(res)
                else reject(res)
            }).catch(err => reject(err))
        })
    },

    put: (path, body) => {
        return new Promise((resolve, reject) => {
            return instance.put(path, body).then((res) => {
                if (res.status < 400) resolve(res)
                else reject(res)
            }).catch(err => reject(err))
        })
    },

    patch: (path, body) => {
        return new Promise((resolve, reject) => {
            return instance.patch(path, body).then(res => resolve(res))
            .catch(err => reject(err))
        })
    },

    delete: (path) => {
        return new Promise((resolve, reject) => {
            return instance.delete(path).then(res => resolve(res))
            .catch(err => reject(err.response.data))
        })
    },

    auth: (path, body) => {
        return new Promise((resolve, reject) => {
            return instance.post(path, body).then((res) => resolve(res))
            .catch(err => reject(err.response.data))
        })
    }
}

for (const i in methods) {
    apis[i] = methods[i]
}

export default apis
