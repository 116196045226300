<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>Lavori</h1>
            </v-col>

            <v-col cols="auto">
                <v-btn
                @click="addDialog()"
                large
                color="success">
                    aggiungi lavoro
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="!loading">
            <v-col
            v-for="job in jobs"
            :key="job._id"
            cols="4">
                <v-card>
                    <v-card-title>
                        {{ job.title }}

                        <v-spacer />

                        <v-menu offset-y>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                fab
                                small
                                v-on="on"
                                v-blind="attrs">
                                    <v-icon>
                                        mdi-dots-vertical
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="edit(job)">
                                    <v-list-item-title>Modifica</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="deleteJob(job._id)">
                                    <v-list-item-title>Elimina</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>

                    <v-card-text>
                        <p v-if="job.desc">
                            {{ job.desc }}
                        </p>

                        <h3 v-if="job.activity">Attività previste</h3>
                        <p v-if="job.activity">
                            {{ job.activity }}
                        </p>

                        <h3 v-if="job.requests">Requisiti del candidato</h3>
                        <ul v-if="job.requests">
                            <li v-for="(request, i) in job.requests" :key="i">
                                {{ request }}
                            </li>
                        </ul>

                        <h3 v-if="job.contract">Tipologia contrattuale</h3>
                        <p v-if="job.contract">
                            {{ job.contract }}
                        </p>

                        <h3 v-if="job.place">Luogo di lavoro</h3>
                        <p v-if="job.place">
                            {{ job.place }}
                        </p>

                        <h3 v-if="job.payment">Retribuzione prevista</h3>
                        <p v-if="job.payment">
                            {{ job.payment }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
        v-model="dialog.on"
        persistent
        max-width="768">
            <v-card>
                <v-card-title>
                    <v-text-field
                    v-model="dialog.data.title"
                    outlined
                    label="Titolo (visibile a tutti)" />

                    <v-spacer />

                    <v-btn
                    @click="dialog.on = false"
                    fab
                    color="secondary">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                    v-model="dialog.data.name"
                    outlined
                    label="Nome interno" />

                    <v-textarea
                    v-model="dialog.data.desc"
                    outlined
                    label="Descrizione" />

                    <v-card outlined class="mb-8">
                        <v-card-title>
                            Requisiti del candidato

                            <v-spacer />

                            <v-btn
                            @click="dialog.data.requests.push('')"
                            fab
                            small
                            color="success">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-list>
                                <v-list-item
                                v-for="(request, i) in dialog.data.requests"
                                :key="i">
                                    <v-text-field
                                    v-model="dialog.data.requests[i]"
                                    outlined
                                    dense
                                    placeholder="Requisito"
                                    append-outer-icon="mdi-delete"
                                    @click:append-outer="dialog.data.requests.splice(i, 1)" />
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="6">
                            <v-textarea
                            v-model="dialog.data.activity"
                            rows="2"
                            outlined
                            label="Attività previste" />
                        </v-col>

                        <v-col cols="6">
                            <v-textarea
                            v-model="dialog.data.contract"
                            rows="2"
                            outlined
                            label="Tipologia contrattuale" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                            v-model="dialog.data.place"
                            outlined
                            label="Luogo di lavoro" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                            v-model="dialog.data.payment"
                            outlined
                            label="Retribuzione prevista" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn
                    @click="dialog.on = false"
                    color="secondary">
                        annulla
                    </v-btn>

                    <v-btn
                    @click="dialogSave()"
                    color="success">
                        salva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'JobView',
    data () {
        return {
            jobs: [],
            loading: true,
            dialog: {
                data: {
                    name: '',
                    title: '',
                    desc: '',
                    activity: '',
                    requests: [],
                    contract: '',
                    place: '',
                    payment: ''
                },
                on: false
            }
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loading = true
            this.$http.get('jobs').then((res) => {
                this.jobs = res.data
                this.loading = false
            }).catch((err) => {
                console.error(err)
                this.loading = false
            })
        },
        setDefault () {
            this.dialog = {
                data: {
                    name: '',
                    title: '',
                    desc: '',
                    activity: '',
                    requests: [],
                    contract: '',
                    place: '',
                    payment: ''
                },
                on: false
            }
        },
        addDialog () {
            this.setDefault()
            this.dialog.on = true
        },
        edit (job) {
            this.dialog = {
                data: job,
                on: true
            }
        },
        dialogSave () {
            const data = this.dialog.data

            if (data._id) {
                this.$http.patch('jobs/' + data._id, data).then(() => {
                    this.setDefault()

                    this.loadData()
                }).catch((err) => console.error(err))
            } else {
                this.$http.post('jobs', data).then(() => {
                    this.setDefault()

                    this.loadData()
                }).catch((err) => console.error(err))
            }
        },
        deleteJob (id) {
            this.loading = true

            this.$http.delete('jobs/' + id).then(() => {
                this.loadData()
            })
        }
    }
}
</script>