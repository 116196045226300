// import CryptoJS from 'crypto-js'

import store from '@/store'
// import router from '@/router'
const CryptoJS = require('crypto-js')
const passphrase = 'MCC'

const utils = {
    parseJwt: () => {
        // const base64Url = token.split('.')[1]
        // const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

        // const jsonPayload = decodeURIComponent(atob(base64))

        // console.log('jsonPayload', jsonPayload)

        // return JSON.parse(jsonPayload)
        return { name: 'Francesco Sasso' }
    },

    encrypt: (str) => {
        return CryptoJS.AES.encrypt(str, passphrase).toString()
    },

    decrypt: (str) => {
        const bytes = CryptoJS.AES.decrypt(str, passphrase)
        return bytes.toString(CryptoJS.enc.Utf8)
    },

    // async getBase64FromURL (input) {
    //     const fr = new FileReader()

    //     fr.readAsDataURL(input)
    //     return await new Promise((resolve) => {
    //         fr.onloadend = () => {
    //             resolve(fr.result)
    //         }
    //     })
    // },

    // getExtensionFromFilename (fileName = '') {
    //     return fileName.split('.').pop()
    // },

    // getFileFromBase64Fetch (base64, name, extension, mime) {
    //     return fetch(base64)
    //     .then(res => res.arrayBuffer())
    //     .then(buffer => new File(
    //         [buffer],
    //         `${name}.${extension}`,
    //         { type: mime }
    //     ))
    // },

    // To Human

    capitalize: (str) => {
        if (!str) return null
        const lower = str.toLowerCase()
        return str.charAt(0).toUpperCase() + lower.slice(1)
    },

    error: (err) => {
        const error = err.response ? err.response.data.message : err
        console.error(error)

        store.commit('SET_SNACKBAR', {
            color: 'error',
            text: error
        })
    },

    getDay: (mode) => {
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()
        if (mode === 'y') return Number(yyyy)
        if (mode === 'm') return Number(mm)
        if (mode === 'd') return Number(dd)

        return yyyy + '-' + mm + '-' + dd
    },

    dateToHuman: (date, mode) => {
        const complete = new Date(date)
        const day = date.substring(0, 10).replace(/-/g, '/')
        const hour = complete.getHours()
        let minutes = '0'
        if (complete.getMinutes().toString().length === 1) {
            minutes = '0' + complete.getMinutes().toString()
        } else {
            minutes = complete.getMinutes().toString()
        }
        const passDay = new Date().getTime() - complete.getTime()
        if (mode === 'c') {
            if (new Date().getDate() === complete.getDate()) return hour + ':' + minutes
            else if (passDay < 86400000) return 'Ieri'
            else if (passDay >= 86400000 && passDay < 864000000) return Math.trunc(passDay / 86400000) + " giorni fa'"
            return day
        } else if (mode === 'chat') {
            if (new Date().getDate() === complete.getDate()) return hour + ':' + minutes
            else if (passDay < 86400000) return hour + ':' + minutes + ' - ' + 'Ieri'
            else if (passDay >= 86400000 && passDay < 864000000) return hour + ':' + minutes + ' - ' + Math.trunc(passDay / 86400000) + " giorni fa'"
            else return hour + ':' + minutes + ' - ' + day
        } else if (mode === 'd') return day
        else if (mode === 'h') return hour
        else if (mode === 'm') return minutes
        else return day + ' - ' + hour + ':' + minutes
    }
}
export default utils

export const getBase64FromURL = async (input) => {
    const fr = new FileReader()

    fr.readAsDataURL(input)
    return await new Promise((resolve) => {
        fr.onloadend = () => {
            resolve(fr.result)
        }
    })
}

export function getExtensionFromFilename (fileName = '') {
    return fileName.split('.').pop()
}

export function getFileFromBase64Fetch (base64, name, extension, mime) {
    return fetch(base64)
    .then(res => res.arrayBuffer())
    .then(buffer => new File(
        [buffer],
        `${name}.${extension}`,
        { type: mime }
    ))
}
