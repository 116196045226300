<template>
    <v-app>
        <v-navigation-drawer v-if="$store.state.login" app permanent>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        Monkee Company
                    </v-list-item-title>

                    <v-list-item-subtitle>
                        Ciao {{ $http.user ? $http.user.name : 'Utente sconosciuto' }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list nav>
                <v-list-item
                v-for="(page, i) in pages"
                :key="i"
                @click="goTo(page.to)"
                link>
                    <v-list-item-icon>
                        <v-icon>
                            {{ page.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            {{ page.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item
                @click="logout()"
                color="success"
                link>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>Esci</v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-snackbar
            v-model="$store.state.snackbar.on"
            :color="$store.state.snackbar.color">
                {{ $store.state.snackbar.text }}
            </v-snackbar>

            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data () {
        return {
            snackbar: true,
            pages: [
                {
                    name: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    to: '/main'
                },
                {
                    name: 'File',
                    icon: 'mdi-file-multiple',
                    to: '/file'
                },
                {
                    name: 'Lavori',
                    icon: 'mdi-account',
                    to: '/job'
                },
                {
                    name: 'Richieste di lavoro',
                    icon: 'mdi-file-document-check',
                    to: '/cv'
                },
                {
                    name: 'Richieste di accesso',
                    icon: 'mdi-shield-account-variant',
                    to: '/access'
                },
                {
                    name: 'Utenti',
                    icon: 'mdi-account',
                    to: '/user'
                },
                {
                    name: 'Log',
                    icon: 'mdi-list-box',
                    to: '/log'
                }
                // {
                //     name: 'Impostazioni',
                //     icon: 'mdi-cog',
                //     to: '/settings'
                // }
            ]
        }
    },
    methods: {
        logout () {
            localStorage.removeItem('token')
            this.$store.commit('SET_LOGIN', false)

            this.goTo('/')
        },
        goTo (page) {
            if (page !== this.$route.path) this.$router.push(page)
        }
    }
}
</script>
