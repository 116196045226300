import Vue from 'vue'
import Vuex from 'vuex'

import http from '@/assets/libs/http'
import {
    getBase64FromURL,
    getExtensionFromFilename,
    getFileFromBase64Fetch
} from '@/assets/libs/utils.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        login: false,
        snackbar: {
            color: 'primary',
            text: '',
            on: false
        }
    },
    getters: {
    },
    mutations: {
        SET_SNACKBAR (state, obj) {
            state.snackbar = {
                ...obj,
                on: true
            }
        },
        SET_LOGIN (state, boo) {
            state.login = boo
        }
    },
    actions: {
        async POST_FILES (contex, source) {
            console.log('source', source)
            try {
                const file = source
                if (!file) {
                    console.error('Nessun file selezionato')
                    return { message: 'Nessun file selezionato' }
                }

                const base64 = await getBase64FromURL(file)

                file.extension = getExtensionFromFilename(file.name)

                const compressedFile = await getFileFromBase64Fetch(base64, file.name, file.extension)

                // uploading
                const fData = new FormData()

                fData.append('file', compressedFile)

                const res = await http.post('files', fData)

                return res
            } catch (err) {
                console.error(err)

                return { message: err.message }
            }
        }
    },
    modules: {
    }
})
