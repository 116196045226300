<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>Utenti</h1>
            </v-col>

            <v-col cols="auto">
                <v-btn
                @click="addDialog()"
                large
                color="success">
                    nuovo utente
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-if="!loading">
            <v-col>
                <v-card>
                    <v-card-title>Utenti</v-card-title>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                            v-for="user in users"
                            :key="user.id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Clienti (area riservata)</v-card-title>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                            v-for="customer in customers"
                            :key="customer.id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ customer.name }} {{ customer.lastName }}</v-list-item-title>

                                    <v-list-item-subtitle>{{ customer.email }}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            icon>
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item @click="edit(customer)">
                                                <v-list-item-title>Modifica</v-list-item-title>
                                            </v-list-item>

                                            <v-list-item @click="deleteCustomer(customer._id)">
                                                <v-list-item-title>Elimina</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
        v-model="dialog.on"
        persistent
        max-width="768">
            <v-card>
                <v-card-title>
                    {{ dialog.data.name }} {{ dialog.data.lastName || '' }}

                    <v-spacer />

                    <v-btn
                    @click="dialog.on = false"
                    fab
                    color="secondary">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <p>Nome: {{ dialog.data.name }}</p>
                            <p>Cognome: {{ dialog.data.lastName }}</p>
                            <p>E-Mail: {{ dialog.data.email }}</p>
                            <p>Ragione sociale: {{ dialog.data.rs }}</p>
                            <p>IVA: {{ dialog.data.iva }}</p>
                        </v-col>

                        <v-col>
                            <v-select
                            :items="imgs"
                            v-model="dialog.data.access"
                            multiple
                            chips
                            outlined
                            label="Accesso ai file" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- <v-dialog
        v-model="dialog.on"
        persistent
        max-width="768">
            <v-card>
                <v-card-title>
                    <v-text-field
                    v-model="dialog.data.title"
                    outlined
                    label="Titolo (visibile a tutti)" />

                    <v-spacer />

                    <v-btn
                    @click="dialog.on = false"
                    fab
                    color="secondary">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                    v-model="dialog.data.name"
                    outlined
                    label="Nome interno" />

                    <v-textarea
                    v-model="dialog.data.desc"
                    outlined
                    label="Descrizione" />

                    <v-card outlined class="mb-8">
                        <v-card-title>
                            Requisiti del candidato

                            <v-spacer />

                            <v-btn
                            @click="dialog.data.requests.push('')"
                            fab
                            small
                            color="success">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-list>
                                <v-list-item
                                v-for="(request, i) in dialog.data.requests"
                                :key="i">
                                    <v-text-field
                                    v-model="dialog.data.requests[i]"
                                    outlined
                                    dense
                                    placeholder="Requisito"
                                    append-outer-icon="mdi-delete"
                                    @click:append-outer="dialog.data.requests.splice(i, 1)" />
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="6">
                            <v-textarea
                            v-model="dialog.data.activity"
                            rows="2"
                            outlined
                            label="Attività previste" />
                        </v-col>

                        <v-col cols="6">
                            <v-textarea
                            v-model="dialog.data.contract"
                            rows="2"
                            outlined
                            label="Tipologia contrattuale" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                            v-model="dialog.data.place"
                            outlined
                            label="Luogo di lavoro" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                            v-model="dialog.data.payment"
                            outlined
                            label="Retribuzione prevista" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn
                    @click="dialog.on = false"
                    color="secondary">
                        annulla
                    </v-btn>

                    <v-btn
                    @click="dialogSave()"
                    color="success">
                        salva
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
    </v-container>
</template>

<script>
export default {
    name: 'JobView',
    data () {
        return {
            users: [],
            customers: [],
            loading: true,
            imgs: [],
            dialog: {
                data: {},
                on: false
            }
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loadImgs()

            this.loading = true
            this.$http.get('users').then((res) => {
                this.users = res.data

                this.$http.get('customers').then((res2) => {
                    this.customers = res2.data
                    this.loading = false
                }).catch((err) => {
                    console.error(err)
                    this.loading = false
                })
            }).catch((err) => {
                console.error(err)
                this.loading = false
            })
        },
        loadImgs () {
            this.$http.get('files').then((res) => {
                this.imgs = res.data.map(this.mapImgs)
            }).catch((err) => console.error(err))
        },
        mapImgs (img) {
            return { text: img.name, value: img._id }
        },
        setDefault () {
            this.dialog = {
                data: {
                    name: '',
                    title: '',
                    desc: '',
                    activity: '',
                    requests: [],
                    contract: '',
                    place: '',
                    payment: ''
                },
                on: false
            }
        },
        addDialog () {
            this.setDefault()
            this.dialog.on = true
        },
        edit (data) {
            this.dialog = {
                data,
                on: true
            }
        },
        dialogSave () {
            const data = this.dialog.data

            if (data._id) {
                this.$http.patch('jobs/' + data._id, data).then(() => {
                    this.setDefault()

                    this.loadData()
                }).catch((err) => console.error(err))
            } else {
                this.$http.post('jobs', data).then(() => {
                    this.setDefault()

                    this.loadData()
                }).catch((err) => console.error(err))
            }
        },
        deleteJob (id) {
            this.loading = true

            this.$http.delete('jobs/' + id).then(() => {
                this.loadData()
            })
        }
    }
}
</script>