<template>
    <v-container>
        <h1 class="mb-1">Main</h1>

        <v-row>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'MainView',
    data () {
        return {
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
        }
    }
}
</script>
