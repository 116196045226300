<template>
    <v-container>
        <h1>Richieste di lavoro</h1>

        <v-data-table
        :loading="loading"
        :headers="headers"
        :items="cvs">
            <template #item.pos="{ item }">
                <v-chip
                v-if="item.pos"
                @click="goTo('/job')"
                color="secondary">{{ item.pos.name }}</v-chip>
                <v-chip
                v-else
                color="secondary">
                    Candidatura spontanea
                </v-chip>
            </template>

            <template #item.email="{ item }">
                <v-chip
                @click="sendMail(item.email)"
                color="secondary">{{ item.email }}</v-chip>
            </template>

            <template #item.file="{ item }">
                <v-chip
                v-if="item.file"
                @click="openURL(item.file.url)"
                color="secondary">{{ item.file.name }}</v-chip>
                <span v-else>[nessun curriculum]</span>
            </template>
            <template #item.createdAt="{ item }">
                {{ $utils.dateToHuman(item.createdAt) }}
            </template>
        </v-data-table>

        <v-row class="mt-4">
            <v-col cols="6">
                <v-card>
                    <v-card-title>Impostazioni notifica</v-card-title>

                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p class="ma-0">Inserisci l'indirizzo a cui verranno inviate le notifiche (come mail) per le nuove iscrizioni</p>
                            </v-col>

                            <v-col>
                                <v-text-field
                                label="Indirizzo di notifica"
                                outlined
                                hide-details="auto" />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />

                        <v-btn
                        color="success">
                            Salva
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'JobView',
    data () {
        return {
            headers: [
                { text: 'Posizione', value: 'pos' },
                { text: 'Nome', value: 'name' },
                { text: 'Cognome', value: 'lastName' },
                { text: 'E-Mail', value: 'email' },
                { text: 'Curriculum', value: 'file', sortable: false },
                { text: 'Data richiesta', value: 'createdAt' }
            ],
            cvs: [],
            loading: true
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loading = true

            this.$http.get('cv').then((res) => {
                this.cvs = res.data
                this.loading = false
            }).catch((err) => {
                console.error(err)
                this.loading = false
            })
        },
        goTo (page) {
            if (page !== this.$route.path) this.$router.push(page)
        },
        sendMail (url) {
            window.open('mailto:' + url)
        },
        openURL (url) {
            window.open(url, '_blank').focus()
        }
    }
}
</script>