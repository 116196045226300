<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>Log</h1>
            </v-col>

            <v-col cols="auto">
                <v-btn
                large
                color="info">esporta i log</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'LogView'
}
</script>