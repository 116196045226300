<template>
    <v-container>
        <div class="center">
            <v-card
            width="768"
            outlined
            :loading="loading">
                <v-card-title>
                    Autenticazione a 2 fattori di Monkee
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                v-model="form.name"
                                outlined
                                label="Nome" />

                                <v-text-field
                                v-model="form.password"
                                @click:append="passVisibility.pass = !passVisibility.pass"
                                :type="passVisibility.pass ? 'password' : 'text'"
                                outlined
                                :append-icon="passVisibility.pass ? 'mdi-eye' : 'mdi-eye-off'"
                                label="Password" />
                            </v-col>

                            <v-col>
                                <v-card outlined>
                                    <v-card-title>
                                        Codice

                                        <v-spacer />

                                        <v-dialog
                                        width="512"
                                        transition="scroll-y-transition">
                                            <template #activator="{ on, attrs }">
                                                <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                fab
                                                small>
                                                    <v-icon>mdi-information-outline</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-card>
                                                <v-card-title>
                                                    Codice
                                                </v-card-title>

                                                <v-card-text>
                                                    Questo codice cambia nel tempo quindi inseriscilo velocemente prima che cambi, lo trovi nell'App di Google Authenticator<br/>Se non vedi nessun codice nell'app crea un nuovo account e ricordati di scansionare il QR che viene generato altrimenti non potrai accedere
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-otp-input
                                        v-model="form.code"
                                        length="6" />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-subheader>Powered by Google Authenticator</v-subheader>

                    <v-spacer />

                    <v-btn
                    @click="dialog = true"
                    color="secondary">Crea nuovo account</v-btn>

                    <v-btn
                    @click="signin"
                    color="success">Accedi</v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <v-dialog
        v-model="dialog"
        persistent
        width="768"
        transition="scroll-y-transition">
            <v-card
            :loading="loading"
            outlined>
                <v-card-title>
                    Nuovo utente

                    <v-spacer />

                    <v-btn
                    @click="dialog = false"
                    fab
                    small
                    color="secondary">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-form>
                                <v-text-field
                                v-model="formNew.name"
                                outlined
                                label="Nome" />

                                <v-text-field
                                v-model="formNew.password"
                                @click:append="passVisibility.pass = !passVisibility.pass"
                                :type="passVisibility.pass ? 'password' : 'text'"
                                outlined
                                :append-icon="passVisibility.pass ? 'mdi-eye' : 'mdi-eye-off'"
                                label="Password" />

                                <v-text-field
                                v-model="formNew.confPass"
                                @click:append="passVisibility.conf = !passVisibility.conf"
                                :type="passVisibility.conf ? 'password' : 'text'"
                                outlined
                                :append-icon="passVisibility.conf ? 'mdi-eye' : 'mdi-eye-off'"
                                label="Conferma Password" />
                            </v-form>
                        </v-col>

                        <v-col cols="auto">
                            <v-sheet
                            rounded
                            :color="!qr ? 'grey lighten-1' : 'white'"
                            class="pa-4">
                                <v-img
                                lazy-src="https://boofcv.org/images/3/35/Example_rendered_qrcode.png"
                                :src="qr"
                                :gradient="!qr ? 'to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)' : ''"
                                max-width="230"
                                max-height="230">
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                            <v-progress-circular
                                            indeterminate
                                            color="gray lighen-5" />
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-subheader>Powered by Google Authenticator</v-subheader>

                    <v-spacer />

                    <v-btn
                    @click="newUser"
                    color="info">Nuovo utente</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'HomeView',
    data () {
        return {
            dialog: false,
            loading: false,
            passVisibility: {
                pass: true,
                conf: true
            },
            form: {
                name: '',
                password: ''
            },
            formNew: {
                name: '',
                password: '',
                confPass: ''
            },
            qr: null
        }
    },
    methods: {
        async newUser () {
            this.loading = true

            this.$http.post('new', this.formNew).then((res) => {
                this.qr = res.data.qr
                this.loading = false
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        },
        async signin () {
            this.loading = true

            this.$http.post('signup', this.form).then((res) => {
                this.$store.commit('SET_SNACKBAR', {
                    color: 'success',
                    text: 'Autenticazione effettuata con successo'
                })
                localStorage.setItem('token', res.data)
                this.$http.updateConfig({ token: res.data })
                console.log('res.data', res.data)

                this.loading = false

                this.$router.push('/main')
            }).catch((err) => {
                this.$utils.error(err)
                this.loading = false
            })
        }
    }
}
</script>

<style>
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
