import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// libs
import http from './assets/libs/http'
import utils from './assets/libs/utils'

Vue.prototype.$http = http
Vue.prototype.$utils = utils

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
