<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>Area protetta</h1>
            </v-col>

            <v-col cols="auto">
                <v-switch
                v-model="active"
                color="warning"
                inset
                label="Form visibile a tutti" />
            </v-col>
        </v-row>

        <v-row v-if="!loading">
            <v-col
            v-for="user in access"
            :key="user._id"
            cols="4">
                <v-card>
                    <v-card-title>
                        {{ user.name }} {{ user.lastName }}

                        <v-spacer />

                        <v-dialog>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                fab
                                small
                                v-on="on"
                                v-blind="attrs">
                                    <v-icon>
                                        mdi-information-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-dialog>
                    </v-card-title>

                    <v-card-subtitle>
                        {{ user.email }}
                    </v-card-subtitle>

                    <!-- <v-card-text>
                        {{ user }}
                    </v-card-text> -->

                    <!-- <v-card-text>
                        <v-form>
                            <v-select />
                        </v-form>
                    </v-card-text> -->

                    <v-card-actions>
                        <v-btn
                        @click="deleteAccess(user._id)"
                        color="error">
                            Rifiura
                        </v-btn>

                        <v-spacer />

                        <v-btn
                        @click="approveAccess(user._id)"
                        color="success">
                            Consenti
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'AccessView',
    data () {
        return {
            access: [],
            loading: true,
            active: true
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        loadData () {
            this.loading = true
            this.$http.get('customers/allow').then((res) => {
                this.access = res.data
                this.loading = false
            }).catch((err) => {
                console.error(err)
                this.loading = false
            })
        },
        approveAccess (id) {
            this.loading = true

            this.$http.post('customer/allow/' + id).then(() => {
                this.loadData()
            })
        },
        deleteAccess (id) {
            this.loading = true

            this.$http.delete('customer/allow/' + id).then(() => {
                this.loadData()
            })
        }
    }
}
</script>