<template>
    <v-container>
        <v-row>
            <v-col>
                <h1>File</h1>
            </v-col>

            <v-col cols="auto">
                <input @change="onFileChanged" ref="uploader" type="file" hidden>

                <v-btn
                @click="importFile"
                :loading="isSelecting"
                large
                color="success">
                    carica file <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
        :loading="loading"
        :items="files"
        :headers="headers">
            <template v-slot:item.img="{ item }">
                <v-img v-if="item.mimetype === 'png' || item.mimetype === 'jpg' || item.mimetype === 'webp'" :src="item.url" />

                <v-icon v-else-if="item.mimetype === 'pdf'" large>
                    mdi-file-pdf-box
                </v-icon>

                <v-icon v-else large>
                    mdi-file
                </v-icon>
            </template>

            <template v-slot:item.url="{ item }">
                <!-- <v-chip @click="openURL(item.url)">{{ item.url }}</v-chip> -->
                <v-chip @click="openURL(item.url)">
                    Open file <v-icon small class="ml-2">mdi-open-in-new</v-icon>
                </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn @click="deleteFile(item._id)" fab small color="error">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: 'FileView',
    data () {
        return {
            files: [],
            loading: false,
            headers: [
                { text: '', value: 'img', sortable: false, align: 'start' },
                { text: 'Nome', value: 'name' },
                { text: 'URL', value: 'url' },
                { text: 'Mimetype', value: 'mimetype' },
                { text: 'Dimensioni', value: 'size' },
                { text: 'Path', value: 'path' },
                { text: 'Delete Path', value: 'deletePath' },
                { text: '', value: 'actions', sortable: false }
            ],
            isSelecting: false,
            selectedFile: null
        }
    },
    created () {
        this.loadData()
    },
    watch: {
        selectedFile (newVal) {
            this.$store.dispatch('POST_FILES', newVal).then(() => {
                this.loadData()
            })
        }
    },
    methods: {
        loadData () {
            this.$http.get('files').then((res) => {
                this.files = res.data
            })
        },
        importFile () {
            this.isSelecting = true

            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true })

            this.$refs.uploader.click()
        },
        onFileChanged (e) {
            this.selectedFile = e.target.files[0]
        },
        deleteFile (id) {
            this.$http.delete('files/' + id).then(() => {
                this.loadData()
            }).catch((err) => console.error(err))
        },
        openURL (url) {
            window.open(url, '_blank').focus()
        }
    }
}
</script>
