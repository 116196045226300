import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainView from '../views/MainView.vue'
import FileView from '../views/FileView.vue'
import JobView from '../views/JobView.vue'
import CvView from '../views/CvView.vue'
import AccessView from '../views/AccessView.vue'
import UserView from '../views/UserView.vue'
import LogView from '../views/LogView.vue'
import SettingsView from '../views/SettingsView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/main',
        name: 'main',
        component: MainView
    },
    {
        path: '/file',
        name: 'file',
        component: FileView
    },
    {
        path: '/job',
        name: 'job',
        component: JobView
    },
    {
        path: '/cv',
        name: 'cv',
        component: CvView
    },
    {
        path: '/access',
        name: 'access',
        component: AccessView
    },
    {
        path: '/user',
        name: 'user',
        component: UserView
    },
    {
        path: '/log',
        name: 'log',
        component: LogView
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('SET_LOGIN', !(!localStorage.getItem('token')))

    if (store.state.login && to.name === 'home') {
        return next('main')
    }

    return next()
})

export default router
